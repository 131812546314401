import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
import React, { useEffect, useRef } from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import styles from './RichText.style.js';
import Img from '../Img';
import Anchor from '../Anchor';
import { parseRichText } from '../../../utils/utilityFunction';
import Video from '../Video/Video.js';
var setAllEmbenddedAssets = function setAllEmbenddedAssets(richTextEmbeddedAsset, assetMap) {
  if (!richTextEmbeddedAsset) return;
  // loop through the assets and add them to the map
  var _iterator = _createForOfIteratorHelper(richTextEmbeddedAsset),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var asset = _step.value;
      assetMap.set(asset.sys.id, asset);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
};
var setAllEmbeddedEntries = function setAllEmbeddedEntries(entriesContent, entryMap) {
  if (!entriesContent) return;
  // loop through the block linked entries and add them to the map
  var _iterator2 = _createForOfIteratorHelper(entriesContent),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var entry = _step2.value;
      entryMap.set(entry.sys.id, entry);
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
};
function renderOptions(entriesContent, richTextEmbeddedAsset, linksContent, inheritedStyles, className) {
  var _renderNode;
  // create an asset map
  var assetMap = new Map();
  // set all the embedded assets
  setAllEmbenddedAssets(richTextEmbeddedAsset, assetMap);

  // create an entry map
  var entryMap = new Map();
  // loop through the block linked entries and add them to the map
  setAllEmbeddedEntries(entriesContent, entryMap);
  setAllEmbeddedEntries(linksContent, entryMap);
  return {
    // other options...

    renderNode: (_renderNode = {}, _defineProperty(_renderNode, INLINES.EMBEDDED_ENTRY, function (node, children) {
      // find the entry in the entryMap by ID
      var entry = entryMap.get(node.data.target.sys.id);
      if (entry.__typename === "Link") {
        var _entry$sys;
        return __jsx(Anchor, {
          "data-entry-id": (_entry$sys = entry.sys) === null || _entry$sys === void 0 ? void 0 : _entry$sys.id,
          title: entry.label,
          to: entry.url,
          hyperlinkType: entry.hyperlinkType,
          ctaBehavior: entry.behavior,
          "aria-label": entry.ariaLabel,
          styleType: 'primary-anchor',
          type: entry.type
        }, entry.label);
      }
    }), _defineProperty(_renderNode, BLOCKS.EMBEDDED_ENTRY, function (node, children) {
      // find the entry in the entryMap by ID
      var entry = entryMap.get(node.data.target.sys.id);
      if (entry.__typename === "Mailchimp") {
        return __jsx("div", {
          className: "nva-rich-text__custom-code",
          dangerouslySetInnerHTML: {
            __html: entry.embedCode
          }
        });
      }
      if (entry.__typename === "Video") {
        var _thumbnail$image, _thumbnail$mobileImag, _thumbnail$mobileImag2, _thumbnail$mobileImag3, _thumbnail$mobileImag4, _thumbnail$mobileImag5, _thumbnail$mobileImag6, _thumbnail$image2;
        var url = entry.url,
          name = entry.name,
          sys = entry.sys,
          thumbnail = entry.thumbnail,
          title = entry.title;
        return __jsx(Video, {
          className: "nva-rich-text__video",
          url: url,
          src: (thumbnail === null || thumbnail === void 0 ? void 0 : (_thumbnail$image = thumbnail.image) === null || _thumbnail$image === void 0 ? void 0 : _thumbnail$image.url) || (thumbnail === null || thumbnail === void 0 ? void 0 : (_thumbnail$mobileImag = thumbnail.mobileImage) === null || _thumbnail$mobileImag === void 0 ? void 0 : (_thumbnail$mobileImag2 = _thumbnail$mobileImag.file) === null || _thumbnail$mobileImag2 === void 0 ? void 0 : _thumbnail$mobileImag2.url) || (thumbnail === null || thumbnail === void 0 ? void 0 : (_thumbnail$mobileImag3 = thumbnail.mobileImage) === null || _thumbnail$mobileImag3 === void 0 ? void 0 : _thumbnail$mobileImag3.url),
          mobileImageUrl: (thumbnail === null || thumbnail === void 0 ? void 0 : (_thumbnail$mobileImag4 = thumbnail.mobileImage) === null || _thumbnail$mobileImag4 === void 0 ? void 0 : (_thumbnail$mobileImag5 = _thumbnail$mobileImag4.file) === null || _thumbnail$mobileImag5 === void 0 ? void 0 : _thumbnail$mobileImag5.url) || (thumbnail === null || thumbnail === void 0 ? void 0 : (_thumbnail$mobileImag6 = thumbnail.mobileImage) === null || _thumbnail$mobileImag6 === void 0 ? void 0 : _thumbnail$mobileImag6.url) || (thumbnail === null || thumbnail === void 0 ? void 0 : (_thumbnail$image2 = thumbnail.image) === null || _thumbnail$image2 === void 0 ? void 0 : _thumbnail$image2.url),
          alt: thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.altText,
          title: (thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.title) || (thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.altText)
        });
      }
    }), _defineProperty(_renderNode, BLOCKS.EMBEDDED_ASSET, function (node, next) {
      // find the asset in the assetMap by ID
      var _assetMap$get = assetMap.get(node.data.target.sys.id),
        url = _assetMap$get.url,
        fileName = _assetMap$get.fileName,
        sys = _assetMap$get.sys,
        width = _assetMap$get.width,
        height = _assetMap$get.height,
        description = _assetMap$get.description;

      // render the asset accordingly
      return __jsx(Img, {
        src: url,
        alt: description,
        title: fileName,
        key: sys.id,
        className: className,
        width: width,
        height: height
      });
    }), _renderNode)
  };
}
var RichText = function RichText(_ref) {
  var jsonContent = _ref.jsonContent,
    entriesContent = _ref.entriesContent,
    linksContent = _ref.linksContent,
    richTextEmbeddedAsset = _ref.richTextEmbeddedAsset,
    ContainerElem = _ref.ContainerElem,
    inheritedStyles = _ref.inheritedStyles,
    className = _ref.className,
    removeEmptyParagraph = _ref.removeEmptyParagraph,
    isHero = _ref.isHero,
    contentfulLivePreview = _ref.contentfulLivePreview,
    shouldBeTabbable = _ref.shouldBeTabbable;
  var richTextContainer = useRef(null);
  useEffect(function () {
    var timer;
    if (richTextContainer !== null && richTextContainer.current !== null) {
      if (isHero && typeof shouldBeTabbable !== 'undefined') {
        timer = setTimeout(function () {
          giveAnchorsATitle(richTextContainer.current, shouldBeTabbable);
        }, 0);
      } else {
        timer = setTimeout(function () {
          giveAnchorsATitle(richTextContainer.current);
        }, 0);
      }
    }
    return function () {
      clearTimeout(timer);
    };
  }, [shouldBeTabbable, isHero, richTextContainer]);
  var giveAnchorsATitle = function giveAnchorsATitle(element, isTabbable) {
    if (element.nodeName == 'A') {
      element.title = element.innerText ? element.innerText : 'rich-text anchor';
      if (isHero && typeof isTabbable !== 'undefined') {
        element.tabIndex = isTabbable ? '0' : '-1';
        element.ariaHidden = isTabbable ? 'false' : 'true';
      }
    }
    var elArr = element.querySelectorAll('a');
    var _iterator3 = _createForOfIteratorHelper(elArr),
      _step3;
    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var anchorElem = _step3.value;
        anchorElem.title = anchorElem.innerText ? anchorElem.innerText : 'rich-text anchor';
        if (anchorElem.getAttribute('href') && anchorElem.attributes && anchorElem.attributes['href'] && anchorElem.attributes['href'].nodeValue && (anchorElem.attributes['href'].nodeValue.includes('http:') || anchorElem.attributes['href'].nodeValue.includes('https:'))) {
          anchorElem.target = '_blank';
          anchorElem.rel = 'noopener noreferrer';
        } else {
          anchorElem.target = '_self';
        }
        if (isHero && typeof isTabbable !== 'undefined') {
          anchorElem.tabIndex = isTabbable ? '0' : '-1';
          anchorElem.ariaHidden = isTabbable ? 'false' : 'true';
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  };
  if (richTextEmbeddedAsset === undefined && entriesContent === undefined) {
    var htmlContent = parseRichText(jsonContent, isHero, removeEmptyParagraph);
    var content = {
      __html: htmlContent
    };
    return __jsx(ContainerElem, _extends({
      ref: richTextContainer,
      dangerouslySetInnerHTML: content,
      className: "nva-rich-text ".concat(className)
    }, contentfulLivePreview));
  } else {
    if (jsonContent !== null && jsonContent !== void 0 && jsonContent.content) {
      return __jsx("div", _extends({
        ref: richTextContainer,
        className: "nva-rich-text ".concat(className)
      }, contentfulLivePreview), documentToReactComponents(jsonContent, renderOptions(entriesContent, richTextEmbeddedAsset, linksContent, inheritedStyles, className)));
    }
  }
};
RichText.defaultProps = {
  inheritedStyles: '',
  ContainerElem: 'div'
};
export default styled(RichText).withConfig({
  componentId: "sc-wturor-0"
})(["", ";"], styles);
export { RichText as RichTextVanilla };